import React from "react"
import { theme } from "@hackclub/design-system"
import styled, { css, keyframes } from "styled-components"

const Flag = (props) => (
  <svg id="Layer_1" viewBox="0 0 288.6 131.9" {...props}>
    <style>
      {".st0{fill:#fff;stroke:#000;stroke-width:3;stroke-miterlimit:10}"}
    </style>
    <path
      className="st0"
      d="M3.3 1s-5.7 55.3 2 75.7 20 21.3 20 21.3 61-41 60.7-39.7-1-5-8-16.7S70.3 6.7 70.3 1"
    />
    <path
      className="st0"
      d="M86.3 59.5s-9.1-11.2-38.4 6C18.6 82.7 22 95.6 25.4 97.9c3.4 2.3 11.1 5.6 41.1-6.6s19.8-31.8 19.8-31.8z"
    />
    <path
      className="st0"
      d="M45.4 100s74.4-61 75.9-61.7 4.7 0 4.7 0l-76 63.1-4.6-1.4z"
    />
    <path
      className="st0"
      d="M53.7 98s-2-9.7-6.3-12.3 4 0 6.3 0S66 91.3 64.3 96.3s-10 6.7-16 3.7 5.4-2 5.4-2zm21-27.7c.7 1.7 7.5 8.6 3.8 9.6s-2.4 9.5 4.9 7.6S93 76 86 72s-11.3-1.7-11.3-1.7zm-8 20s-1-3.7-3.7-9.7 7 0 7 0S82 86 77.3 93s-11 1.8-12.8 0c-1.8-1.8-2.2-4.3 2.2-2.7z"
    />
    <path
      d="M129.7 30s23.6-6.1 58.9 35.7c35.3 41.7 94.3 31.8 95 31.6.7-.2-16.3 11.5-16.3 11.5s9.1 6.7 18.7 8.5c9.5 1.8-56.4 26.4-100.5 5.7-23.9-10.4-41.3-25.9-52.8-41-4.1-5-16.9-22-34.7-24.8L129.7 30z"
      fill="#e42d40"
      stroke="#000"
      strokeWidth={3}
      strokeMiterlimit={10}
    />
    <path
      d="M136.7 41.6c-1.8 2.3-4.9 6.2-9.7 10.8-8.5 7-1.3 8.9 2.4 6.1 2.2-1.5 4.5-3.1 4.5-3.1l4.6 5.2s-1.9 2.3-3.4 4.2c-4.7 4.6.9 6.8 4.5 3.3 1.8-1.8 9.5-9 12.9-13.3 2.9-2.4 0-7.3-4.5-3.5-2 1.3-5.7 4.2-5.7 4.2s-3.8-4.3-3.7-4.5c.1-.6 1.3-1.5 1.9-2.7 3.8-7.9-1.3-9.8-3.8-6.7zm21 19.1c2.8-2.4 4.8-2.1 6-1.4.5.3.8 1.7.9 3.1-1.3 8-10.6 19.6-11.5 20.3-3.5 2.4-6.9.2-3.9-2.9 1.6-1.9 3.3-3.6 3.3-3.6l-4.4-2.6c-6.9 5.5-8.8 1.1-5.5-1.2 3.3-2.6 11.3-8.6 15.1-11.7zm-5.7 8.5l4.3 2.8s5-7.6 3.1-7.3l-7.4 4.5zm26.6 9c1.4-1.5 1.9-5.9-3-7.2-6.6-2.9-13.9 7.4-13.9 7.4s-3.5 5-3.5 9.4c-.1 4.3 3.7 6 4.4 6.4 4.8.7 4.6-2.9 4-3.2-.9-.5-2.2-.9-2.9-2.1-1.1-2.2.7-5.5 2-7.2 2.3-2.8 7.1-8.2 9.5-5.3.8 3 2.6 2.6 3.4 1.8zm4 1.6c-2.7 3-2.9 7.2-8.7 12.6-1.4 1.3-4.4 3.5-4.8 3.9-2.9 2.3-.5 5.4 5.4 1.9 1.4-.8 3.7-3.4 3.7-3.4l1.6.5s-1.3 6.5-2.1 9.1c-.8 2.6 3 5.9 5.2 1.1.6-1.7 1-4.2 1.2-7.5 0 0 .2-2.4-.4-3 0 0 7-2.6 8.4-4.3 1.3-1.7 1.1-3.5-4.2-2-5.2 1.5-6.1 2.3-6.1 2.3s2.7-3.6 3.9-6.7c1.7-2.9-.8-6.8-3.1-4.5zM200 93.4c-2.3.8-4.7 3.3-6.5 7.9-2.8 6.8-1.6 11.8 3.5 13.1 5.1 1.4 5.5-2.5 5.2-2.8-.3-.3-.3-.7-2.5-.8-2.3-.1-3.4-1.6-3.3-5.1.1-2.3 1.4-6.5 3.8-7.9.7-.4 1.2-.5 1.5-.5 1.1-.1 1.8.7 1.9 1 1.7 2.9 3.1 1.1 2.9-1-.8-3.2-2.9-4.7-6.5-3.9zm9.7 6.6c1.1-2.5 4.6-3.4 3.1 2.7-.5 4.2-2 11.8-2.5 12.9 0 0 1.2 1.4 5.8 2.4 0 0 2 .7 1.1 1.6-.9 1-2 .6-2.2.6-.3 0-10.6-2.3-10.2-3.7.4-1.4 1-1.9 2.3-6.2 1.1-5.7 1.5-7.8 2.6-10.3zm11.2 4.1c.6-1.9 3-2.2 3.2.2.2 1.9-1.8 9-1.7 12.4.3 1.4 1.8 2.3 2.9 1.6 1.6-1.3 2.7-5.3 3.5-8.8.3-1.7.7-2.9 1.2-3.7 1.5-2.7 3.6-1 3.4 2.4-.6 3.6-2.4 9.1-3.6 10.7-1.2 1.6-2.7 3.6-5.3 3.1-.7-.1-6-1.6-5.3-7.9.8-6.1 1.1-8.1 1.7-10zm18.5 1.1c3.9-.4 6.5-.1 8.1 1.4.8 1.1.6 3.2-.3 4.6-.9 1.1-2 2-2 2 1.7 1 1.6 2.9 1.6 2.9-.1 7.3-4 6.9-8.8 6.3-1.5-.3-3.1-.9-2.9-1.5.3-1 1.9-13.9 1.9-13.9 0-.7 0-1.7 2.4-1.8zm.3 2.6l-.1 4.3s4.5.2 4.9-2c.5-2.3-2.4-2.2-4.8-2.3zm-.7 7.3s-1 4.9-.8 4.6c.1-.3 5.7.6 5.8-2.7 0-3.3-5-1.9-5-1.9z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={0.25}
      strokeMiterlimit={10}
    />
  </svg>
)

const waveFlag = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-5deg);
  }
`

const waveFlagScaled = keyframes`
  from {
    transform: scale(.75) rotate(0deg);
  }
  to {
    transform: scale(.75) rotate(-5deg);
  }
`

const OrpheusFlag = styled(Flag)`
  flex-shrink: 0;
  height: 50px;
  width: 109.391px;
  transition: ${theme.transition} transform;
  transform-origin: top left;
  &:hover,
  &:focus {
    animation: ${waveFlag} 0.5s linear infinite alternate;
  }
  ${theme.mediaQueries.reduceMotion} {
    animation: none !important;
  }
  ${(props) =>
    props.scrolled &&
    css`
      transform: scale(0.75);
      height: 44px !important;
      &:hover,
      &:focus {
        animation: ${waveFlagScaled} 0.5s linear infinite alternate;
      }
    `};
`
OrpheusFlag.defaultProps = {
  to: "/",
  "aria-label": "Homepage",
}

export default OrpheusFlag
