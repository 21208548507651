import React from "react"

const Logo = (props) => (
  <svg viewBox="0 0 121.27 139.06" {...props}>
    <defs>
      <style>
        {".cls-1{fill:#7fd0d8}.cls-3{fill:#d7f0f3}.cls-4{fill:#e8d01d}"}
      </style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="Layer_1-2-2" data-name="Layer 1-2-2">
          <path
            d="M116.62 78.52c2.1 1.23 6.5 5.3 4.3 13.33 0 0-2.1 16.5-2.3 17.8s-11.7 5.2-14.1 5.2h-.3c-1.5-.5-2.9 18.7-8 18.8l-2.11.17-.19-2.27c7.4-.2 8-68.1 9-69.57l2.53-.45 1.17 6.62c2.1 5.17 5.3 8.97 10 10.37zm.9 5.73c.3-4.3-8-5.1-9.2-4.8-3.2.7-5.5 28.5-3.8 28.6 2.3 1.4 10.6-.1 11.2-3s1.5-16.5 1.8-20.8z"
            transform="translate(-.25)"
          />
          <path
            className="cls-1"
            d="M108.32 79.45c1.2-.3 9.5.5 9.2 4.8s-1.8 20.77-1.8 20.77c-.6 2.9-8.9 4.4-11.2 3-1.7-.02.6-27.87 3.8-28.57z"
            transform="translate(-.25)"
          />
          <path
            d="M105.22 60.22l.23 1.28-2.53.45c-.6-1-1.3-1.1-2.1-.1.06 5.23-7.58 8.74-14.92 9.09v-1C94.61 69.43 98.09 55.52 87 53l-.6.14-.23-2c6.83-.86 14.14 1.17 19.05 9.08z"
            transform="translate(-.25)"
          />
          <path
            className="cls-1"
            d="M100.82 61.85c.8-1 1.5-.9 2.1.1-1 1.47-1.6 69.37-9 69.57l-62 4.2c-10.2 3.3-6.7-65.7-6.4-67.2.6 7.4 19.6 7.5 22.8-2.5H53c.34.42.66.85 1 1.28 3.67 5.25 4.84 10.81 15.84-.28.5-.6 3-2.1 3.7-.8 2 3.56 7.1 5 12.38 4.69 7.32-.32 14.96-3.83 14.9-9.06zm-21.2 48.87c12.1 1.4 20.8-30.7 3.2-33.4-25.1-3.8-16.3 35.5-3.2 33.4zm-5.5 10.2c.6-1.2-.8-2.5-2.4-2.1a14.41 14.41 0 00-4.9 2.1A8.45 8.45 0 0162 123c-2 .2-4.1.7-6.1.4-2.3-.4-4.5-1.6-6.8-2.4a6.06 6.06 0 00-1.4-.2c.2.5.3 1 .5 1.5a.78.78 0 00.4.4c1.4.7 2.7 1.6 4.2 2.2a13.86 13.86 0 006 1.4c4.8-.1 8.43-.7 11.4-2.9.7-.5 1.6-.7 2.3-1.2a3.45 3.45 0 001.62-1.28zm-31.8-10.2c12.1 1.4 20.8-30.7 3.2-33.4-25.1-3.8-16.27 35.5-3.2 33.4z"
            transform="translate(-.25)"
          />
          <path
            d="M93.82 4.82a2.69 2.69 0 011.43 2.8l-1.33 8.13a2.65 2.65 0 01-2.2 2.25l-8.2 1.3a2.69 2.69 0 01-2.8-1.4l-3.8-7.4a2.74 2.74 0 01.5-3.1l5.9-5.9a2.74 2.74 0 013.1-.5z"
            transform="translate(-.25)"
            fill="#a87eea"
          />
          <path
            d="M93.92 131.55l.19 2.27-64.19 5.23c-5.6.5-9-15.8-9-15.7h-2.6A14.6 14.6 0 015 118.55c-7.6-13.6-6.4-25.1 5.6-34.1l3.5-3a27.05 27.05 0 006-8.3 75.2 75.2 0 005.2-15.8c3.1-4.5 6.8-8 15.3-3.1l4.9 5.1c1.1 1.7 1.5 1.3 1.6-.3-2.3-5.7-3.5-16.7 1.2-16.6l10.7 2.6c1.4 0 1.9-.6 1.8-1.5l-.8-13.4c-.4-1.6.5-2.4 2.4-2.5l14.6 3.5c2.2.2 3.1-.5 2.3-2.4l-1.4-5c-.2-2.2 1.2-2.2 2.6-2l.4.1s2.8 10.2 1.1 11.4c-1.5 1.1-9-1.1-15.8-2.9-1.8-.5-2.8-.1-2.5 2l.7 9.9c-.7 5.7-4.5 5.6-8.6 4.9l-5.1-1.3c-.9-.2-1.4.1-1.4 1.1l.3 3 .5 2.8.6 5.9c.5 1.2 1.1 1.3 1.8.2l.27-.55 1.08.5c-1.27 2.15-.21 5.55 1.68 8.28l-1.53.25c-.3-.43-.62-.86-1-1.28h-4.7c-3.2 10-22.2 9.9-22.8 2.5-.3 1.5-3.8 70.5 6.4 67.2zm-58.2-59.8c11.4-2.7 2.4-17.4-3.6-16.3-5.87 1.07-7.5 15.37 3.6 16.3zm-15 46.37c.8-12.37-.3-23.6-4.8-32.4-23.5 8.28-7.2 37.5 4.8 32.4z"
            transform="translate(-.25)"
          />
          <path
            className="cls-3"
            d="M77.42 84.12c1.4-9.8 15.4-2 12.4 6.8.6 2.2-7.9 5.4-10 1.8-1.4-2.4-2.4-8.3-2.4-8.6z"
            transform="translate(-.25)"
          />
          <path
            className="cls-4"
            d="M87 53c11.07 2.5 7.59 16.41-1.16 16.91h-1c-9-3.74-10.55-13.68 1.6-16.76z"
            transform="translate(-.25)"
          />
          <path
            className="cls-3"
            d="M87.82 100.32c.7 10.5-18.8 9.5-18-3.7.1-1.5-1.2-8.1 2-8.7 4.3-.8 4.7 7.9 6.1 9 1.2 1 7.7 2.1 7.9 2 1.6-.3 1.8-.3 2 1.4z"
            transform="translate(-.25)"
          />
          <path
            d="M86.19 51.12l.23 2c-12.15 3.08-10.56 13-1.6 16.76h1v1c-5.28.26-10.41-1.13-12.38-4.69-.7-1.3-3.2.2-3.7.8-11 11.09-12.17 5.53-15.84.28l1.57-.25c1.77 2.57 4.27 4.55 6.27 4.37 12.8-8.8 1-16.7-4.1-14.7a4.71 4.71 0 00-3.85 2l-1.08-.5c4.09-7.54 16.07-.82 17.73.45 3.16-3.31 9.23-6.73 15.75-7.52zM82.82 77.32c17.6 2.7 8.9 34.8-3.2 33.4-13.1 2.1-21.9-37.2 3.2-33.4zm7 13.6c3-8.8-11-16.6-12.4-6.8 0 .3 1 6.2 2.4 8.6 2.1 3.6 10.6.4 10-1.8zm-20 5.7c-.8 13.2 18.7 14.2 18 3.7-.2-1.7-.4-1.7-2-1.4-.2.1-6.7-1-7.9-2-1.4-1.1-1.8-9.8-6.1-9-3.2.6-1.9 7.2-2 8.7zM71.72 118.82c1.6-.4 3 .9 2.4 2.1a3.45 3.45 0 01-1.6 1.3c-.7.5-1.6.7-2.3 1.2-3 2.2-6.6 2.8-11.4 2.9a13.86 13.86 0 01-6-1.4c-1.5-.6-2.8-1.5-4.2-2.2a.78.78 0 01-.4-.4c-.2-.5-.3-1-.5-1.5a6.06 6.06 0 011.4.2c2.3.8 4.5 2 6.8 2.4 2 .3 4.1-.2 6.1-.4a8.45 8.45 0 004.8-2.1 14.41 14.41 0 014.9-2.1z"
            transform="translate(-.25)"
          />
          <path
            className="cls-4"
            d="M57.72 56.75c5.1-2 16.9 5.9 4.1 14.7-2 .18-4.5-1.8-6.27-4.37-1.89-2.73-2.95-6.08-1.68-8.28a4.71 4.71 0 013.85-2.05z"
            transform="translate(-.25)"
          />
          <path
            className="cls-3"
            d="M40.12 84.12c1.4-9.8 15.4-2 12.4 6.8.6 2.2-7.9 5.4-10 1.8-1.4-2.4-2.52-8.3-2.4-8.6z"
            transform="translate(-.25)"
          />
          <path
            className="cls-3"
            d="M50.52 100.32c.7 10.5-18.9 9.5-18-3.7.1-1.5-1.2-8.1 2-8.7 4.3-.8 4.9 8 6.1 9s7.7 2.1 7.9 2c1.6-.2 1.8-.3 2 1.4z"
            transform="translate(-.25)"
          />
          <path
            d="M45.52 77.32c17.6 2.7 8.9 34.8-3.2 33.4-13.07 2.1-21.9-37.2 3.2-33.4zm7 13.6c3-8.8-11-16.6-12.4-6.8-.1.3 1 6.2 2.4 8.6 2.1 3.6 10.6.4 10-1.8zm-20 5.7c-.9 13.2 18.7 14.2 18 3.7-.2-1.7-.4-1.6-2-1.4-.2.1-6.7-1-7.9-2s-1.8-9.8-6.1-9c-3.2.6-1.9 7.2-2 8.7z"
            transform="translate(-.25)"
          />
          <path
            className="cls-4"
            d="M32.12 55.45c6-1.1 15 13.6 3.6 16.3-11.1-.93-9.47-15.23-3.6-16.33z"
            transform="translate(-.25)"
          />
          <path
            className="cls-1"
            d="M15.92 85.72c4.5 8.8 5.6 20 4.8 32.4-12 5.1-28.3-24.12-4.8-32.4z"
            transform="translate(-.25)"
          />
          <path
            d="M91.47 18l-8.2 1.3a2.69 2.69 0 01-2.8-1.4l-3.8-7.4a2.74 2.74 0 01.5-3.1l5.9-5.9a2.74 2.74 0 013.1-.5l7.4 3.8A2.69 2.69 0 0195 7.6l-1.33 8.13a2.65 2.65 0 01-2.2 2.27z"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            strokeWidth="1.5px"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default Logo
